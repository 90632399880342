<template>
    <AccessControl :menuId="5" :menuSectionId="13" nameOfPage='Item Adjustment List' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">

                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Supplier Performance Report
                        </h1>
                    </div>
                    
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header card-header-bg flex-wrap gap-2">
                        <div class="flex flex-wrap gap-2 lg:gap-5">
                            <div class="flex"><label class="input input-sm"><i class="ki-filled ki-magnifier"></i><input
                                        value="" v-model="searchTerm" type="text" placeholder="Search table"
                                        @input="fetchData"></label>
                                <label class="input input-sm" style="margin-left:10px;">Date From:<input value=""
                                        v-model="dateFrom" type="date" @input="fetchData"></label>


                                <label class="input input-sm" style="margin-left:10px;">Date To:<input value=""
                                        v-model="dateTo" type="date" @input="fetchData"></label>
                                <div>

                                    <button :disabled="export_loading" type="button" style="margin-left:10px;"
                                        @click="generate_report" ref="addRow"
                                        class="w-32 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-1">
                                        <span v-if="export_loading" class="spinner"></span>
                                        <span v-else>Generate Report</span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <vue-good-table mode="remote" :server-side="true" :columns="columns" :rows="rows"
                        :total-rows="totalRows" :pagination-options="paginationOptions" :isLoading.sync="isLoading"
                        :search-options="{ enabled: false }" @page-change="onPageChange" @on-page-change="onPageChange"
                        @per-page-change="onPerPageChange" @sort-change="onSortChange">
                        <template #table-row="props">
                           
                        </template>

                    </vue-good-table>

                </div>
            </div>
            <!-- end: container -->

        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal3.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';
import Notification from '@/components/utils/Notification.vue';
import AccessControl from '@/components/utils/UserAccess.vue';


export default {
    name: 'SupplierListPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        AccessControl
    },
    async mounted() {
        let code = this.$route.query.code; // Accessing route params via 'this'

        if (code != "") {
            this.searchTerm = code;
        }
    },
    setup() {
        const columns = ref([
            { label: '#', field: 'purchase_order_id', type: 'number', sortable: true },           
            { label: 'Supplier Name', field: 'supplier_name', sortable: true },
            { label: 'Quantity', field: 'order_quantity', sortable: true },
            { label: 'Order Date', field: 'purchase_order_date', sortable: true },
            { label: 'Delivered Date', field: 'delivered_date', sortable: true },
            { label: 'Lead Time in A day', field: 'lead_time', sortable: true },


            

           
            
        ]);

        const rows = ref([]);
        const totalRows = ref(0);
        const searchTerm = ref('');
        const currentPage = ref(1);
        const perPage = ref(20);
        const dateFrom = ref('');
        const dateTo = ref('');

        const sortField = ref('supplier_name');
        const sortType = ref('desc');

        const paginationOptions = ref({
            enabled: true,
            mode: 'pages',
            perPageDropdown: [5, 10, 20],
            perPage: perPage.value,
            totalRecords: totalRows.value,
            currentPage: currentPage.value

        });

        const searchOptions = {
            enabled: true
        };

        // Function to fetch data from the server
        const fetchData = async (page) => {
            try {
                const params = {
                    page: page,
                    per_page: perPage.value,
                    sort_field: sortField.value,
                    sort_type: sortType.value,
                    search: searchTerm.value,
                    date_from: dateFrom.value,
                    date_to: dateTo.value
                };
                const { data } = await apiClient.get('/reports/supplier_performance', { params });
                rows.value = data.data || [];
                totalRows.value = data.total || 0;

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const onPageChange = ({ currentPage }) => {
            paginationOptions.currentPage = currentPage;
            fetchData(currentPage);
        };

        const onPerPageChange = ({ currentPerPage }) => {
            console.log('Per page changed to:', currentPerPage);
            perPage.value = currentPerPage;
            paginationOptions.value.perPage = currentPerPage;
            fetchData();
        };

        const onSortChange = ([sort]) => {
            console.log('Sort changed to:', sort);
            sortField.value = sort.field;
            sortType.value = sort.type;
            fetchData();
        };
        // Watch for changes to trigger fetchData
        watch([currentPage, perPage, sortField, sortType, searchTerm, dateFrom, dateTo], fetchData, { immediate: true });
        return {
            columns,
            rows,
            totalRows,
            paginationOptions,
            searchTerm,
            onPageChange,
            onPerPageChange,
            onSortChange,
            isLoading: false,
            dateFrom,
            dateTo
        };
    },
    methods: {

        async generate_report() {

            let fromDate = this.dateFrom;
            let toDate = this.dateTo;
            let searchTerm = this.searchTerm;

            if (fromDate == "" || toDate == "") {
                this.$refs.notificationRef.show("Please select Date Range", 'error', '0');
                return false;
            }
            this.export_loading = true; // Set loading state to true
            const params = {
                search: searchTerm,
                date_from: fromDate,
                date_to: toDate
            };

            try {
                const response = await apiClient.get(`/exports/supplier_performance`, {
                    params,
                    responseType: 'blob', // Important for binary data
                });

                // Create a blob from the response data
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'supplier_performance.xlsx'); // Set the desired file name
                document.body.appendChild(link);
                link.click();
                // Clean up the link
                link.parentNode.removeChild(link);
                this.export_loading = false;
            } catch (error) {
                console.error('Error downloading customer report:', error);
            }

        },
        async showDetails(data) {
            let id = data.item_adjustment_id;
            this.showModalDetail = true;
            try {
                const response = await apiClient.get(`/item_adjustment/detail/${id}`);
                if (response.data.status == true) { // or check for other success c
                    this.base_info = response.data.data.info;
                    this.items_list = response.data.data.items; // Store the fetched data 



                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
            }
        },       
        handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },


    },
    data() {
        return {
            showModal: false,
            form: {},
            showModal2: false,
            currentItem: [

            ],
            countty_list: [],
            province_list: [],
            city_list: [],
            edit_first: 0,
            items_list: {},
            showModalDetail: false,
            base_info: {},
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,
            export_loading: false,

        };
    },


}
</script>


<style></style>